const burger = (menuId, burgerSelector) => {
  let menuOpen = false;
  const menu = document.getElementById(menuId);
  const showMobileNav = () => {
    if (menuOpen) {
      menu.style.display = 'none';
      menuOpen = false;
    } else {
      menu.style.display = 'block';
      menuOpen = true;
    }
  };
  const updateMenu = () => {
    const menu = document.getElementById(menuId);
    if (window.innerWidth > 625) {
      if (menu.style.display === 'none') {
        menu.style.display = 'flex';
        menuOpen = false;
      }
    } else {
      if (menu.style.display === 'flex') {
        menu.style.display = 'none';
        menuOpen = false;
      }
    }
  };
  const hideMenu = () => {
    if (window.innerWidth < 625) {
      menu.style.display = 'none';
      menuOpen = false;
    }
  };
  document
    .querySelector(burgerSelector)
    .addEventListener('click', showMobileNav);
  window.onresize = updateMenu;
  return hideMenu;
};

export default burger;
