import * as d3 from 'd3';
const makeXGridlines = (xScale) => d3.axisBottom(xScale).ticks(xAxisTicks);
const makeYGridlines = (yScale) => d3.axisLeft(yScale).ticks(yAxisTicks);
const margin = { top: 15, right: 0, bottom: 15, left: 50 };

const xAxisTicks = 6;
const yAxisTicks = 6;

const chart = (data, selector, animBlip, isDesktop) => {
  const drawChart = () => {
    const container = document.getElementById(selector.replace('#', ''));
    container.style.padding = '10px 10px 0';
    container.style.margin = '0 0 10px';
    if (container.childNodes.length) return;
    const width = container.clientWidth - margin.left - margin.right - 20;
    const height = 155 - margin.top - margin.bottom;
    const bisect = d3.bisector(function (d) {
      return d.x;
    }).left;

    const svg = d3
      .select(selector)
      .append('svg')
      .attr('width', 750)
      .attr('height', 155)
      .attr('viewBox', '0 0 ' + width + ' ' + 160)
      .attr('preserveAspectRatio', 'xMinYMid')
      .append('g')
      .attr('transform', `translate(${margin.left}, 2.5)`);

    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(data, (d) => d.x))
      .range([0, width]);

    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(xAxisTicks)
          .tickFormat((d) => d + ' km')
          .tickSize(0)
          .tickPadding(9)
      );

    const yScale = d3
      .scaleLinear()
      .domain([
        d3.min(data, (co) => co.y),
        d3.max(data, (co) => co.y) < 500 ? 500 : d3.max(data, (co) => co.y),
      ])
      .range([height, 0]);

    svg.append('g').call(
      d3
        .axisLeft(yScale)
        .ticks(yAxisTicks)
        .tickFormat((d) => d + ' m')
        .tickSize(0)
        .tickPadding(8)
    );

    const area = d3
      .area()
      .x((d) => xScale(d.x))
      .y0(yScale(yScale.domain()[0]))
      .y1((d) => yScale(d.y))
      .curve(d3.curveCatmullRom.alpha(0.005));

    svg
      .append('path')
      .attr('d', area(data))
      .attr('class', 'elevationChartLine')
      .style('stroke', '#fb4471')
      .style('stroke-opacity', 0.6)
      .style('stroke-width', 1)
      .style('fill', '#fb4471')
      .style('fill-opacity', 0.6);

    const crossBar = svg
      .append('g')
      .attr('class', 'crossBar')
      .style('display', 'none');

    crossBar
      .append('line')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', height)
      .attr('y2', 0);

    crossBar
      .append('text')
      .attr('x', 10)
      .attr('y', 17.5)
      .attr('class', 'crossBarText');

    const infoBox = svg
      .append('g')
      .attr('class', 'infoBox')
      .style('display', 'none');

    const infoBoxElevation = infoBox
      .append('text')
      .attr('x', 8)
      .attr('y', 30)
      .attr('class', 'infoBoxElevation');

    infoBoxElevation
      .append('tspan')
      .attr('class', 'infoBoxElevationTitle')
      .text('Elev: ');

    infoBoxElevation.append('tspan').attr('class', 'infoBoxElevationValue');

    // MOUSE IN / OUT EVENTS
    const mousemove = (e) => {
      const x0 = xScale.invert(d3.pointers(e)[0][0]);
      const i = bisect(data, x0, 1);
      const d0 = data[i - 1];
      const d1 = data[i];
      const d = !d1 ? d0 : x0 - d0.x > d1.x - x0 ? d1 : d0;
      const isBoxRight = i < data.length / 2;
      animBlip(i);
      crossBar.attr('transform', `translate(${xScale(d.x)}, 0)`);
      crossBar
        .select('text')
        .text(d3.format('.1f')(d.x) + ' km')
        .attr('transform', `translate(${isBoxRight ? 10 : -10 - 100}, 0)`);
      infoBox.attr(
        'transform',
        `translate(${
          isBoxRight ? xScale(d.x) + 10 : xScale(d.x) - 10 - 100
        }, 12.5)`
      );
      infoBox
        .select('.infoBoxElevationValue')
        .text(d3.format(',.0f')(d.y) + ' m');
    };
    const chartOverlay = svg
      .append('rect')
      .attr('class', 'chartOverlay')
      .attr('width', width)
      .attr('height', height);

    // Setup event listeners for overlay
    if (isDesktop) {
      chartOverlay
        .on('mouseover', function () {
          crossBar.style('display', null);
          infoBox.style('display', null);
        })
        .on('mouseout', function () {
          crossBar.style('display', 'none');
          infoBox.style('display', 'none');
          animBlip();
        })
        .on('mousemove', mousemove);
    } else {
      chartOverlay
        .on('touchstart', function () {
          crossBar.style('display', null);
          infoBox.style('display', null);
        })
        .on('touchend', function () {
          crossBar.style('display', 'none');
          infoBox.style('display', 'none');
          animBlip();
        })
        .on('touchmove', mousemove);
    }
  };
  return drawChart;
};

export default chart;
