import * as d3 from 'd3';

export const animatePath = ({ g, svg, map, L, route }) =>
  new Promise((resolve, reject) =>
    d3.json(route.geo).then((collection) => {
      let topLeft, bottomRight;
      // Select
      const featuresdata = collection.features[0].geometry.coordinates[0];
      const transform = d3.geoTransform({
        point: projectPoint,
      });

      //d3.geoPath translates GeoJSON to SVG path codes.
      const d3path = d3.geoPath().projection(transform);

      //Filter for the outside glow
      const filter = svg.append('filter').attr('id', 'glow');
      filter
        .append('feGaussianBlur')
        .attr('stdDeviation', '1.5')
        .attr('result', 'coloredBlur');
      const feMerge = filter.append('feMerge');
      feMerge.append('feMergeNode').attr('in', 'coloredBlur');
      feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

      // Here we will make the points into a single
      // line/path.
      const linePath = g
        .selectAll('.lineConnect')
        .data([featuresdata])
        .enter()
        .append('path')
        .attr('class', 'lineConnect')
        .style('filter', 'url(#glow)');

      // This will be our traveling circle it will
      // travel along our path
      const marker = g
        .append('circle')
        .attr('r', 1)
        .attr('id', 'marker')
        .attr('class', 'travelMarker');

      const blip = g
        .append('circle')
        .attr('r', 4)
        .attr('class', 'blipMarker')
        .style('display', 'none');

      const showBlip = (index = false) => {
        if (index === false || featuresdata[index] === undefined) {
          blip.style('display', 'none');
          return;
        }
        blip.style('display', null).attr('transform', () => {
          const y = featuresdata[index][1];
          const x = featuresdata[index][0];
          return (
            'translate(' +
            map.latLngToLayerPoint(new L.LatLng(y, x)).x +
            ',' +
            map.latLngToLayerPoint(new L.LatLng(y, x)).y +
            ')'
          );
        });
      };

      // !! Hardcoded
      const originANDdestination = [
        {
          latlng: featuresdata[0],
          class: 'trackStart',
        },
        {
          latlng: featuresdata[featuresdata.length - 1],
          class: 'trackEnd',
        },
      ];

      const begend = g
        .selectAll('.startEnd')
        .data(originANDdestination)
        .enter()
        .append('circle', '.startEnd')
        .attr('r', 4)
        .attr('class', (d) => d.class)
        .style('opacity', '1');

      // Reposition the SVG to cover the features.
      const reset = () => {
         // Here we're creating a FUNCTION to generate a line
        // from input points.
        const toLine = d3
          .line()
          .x((d) => applyLatLngToLayer(d).x)
          .y((d) => applyLatLngToLayer(d).y);
        const applyLatLngToLayer = (d) => {
          // console.log(d);
          const y = d[1];
          const x = d[0];
          return map.latLngToLayerPoint(new L.LatLng(y, x));
        };

        const bounds = d3path.bounds(collection);
        topLeft = bounds[0];
        bottomRight = bounds[1];
        // for the points we need to convert from latlong
        // to map units
        begend.attr('transform', (d) => {
          return (
            'translate(' +
            applyLatLngToLayer(d.latlng).x +
            ',' +
            applyLatLngToLayer(d.latlng).y +
            ')'
          );
        });

        // again, not best practice, but I'm harding coding
        // the starting point
        marker.attr('transform', () => {
          const y = featuresdata[0][1];
          const x = featuresdata[0][0];
          return (
            'translate(' +
            map.latLngToLayerPoint(new L.LatLng(y, x)).x +
            ',' +
            map.latLngToLayerPoint(new L.LatLng(y, x)).y +
            ')'
          );
        });

        // Setting the size and location of the overall SVG container
        svg
          .attr('width', bottomRight[0] - topLeft[0] + 120)
          .attr('height', bottomRight[1] - topLeft[1] + 120)
          .style('left', topLeft[0] - 50 + 'px')
          .style('top', topLeft[1] - 50 + 'px');

        g.attr(
          'transform',
          'translate(' + (-topLeft[0] + 50) + ',' + (-topLeft[1] + 50) + ')'
        );
        
        linePath.attr('d', toLine);
      }; // end reset

      const transition = () => {
        // const z = map.getZoom();
        // map.setZoom(z + 1);
        linePath
          .transition()
          .duration(2000)
          .attrTween('stroke-dasharray', tweenDash)
          .on('end', () => resolve({ draw, showBlip }));
        // d3.select(this).call(transition); // infinite loop
      }; //end transition

      // this function feeds the attrTween operator above with the
      // stroke and dash lengths
      const tweenDash = () => {
        return (t) => {
          const l = linePath.node().getTotalLength();
          const interpolate = d3.interpolateString('0,' + l, l + ',' + l);
          const marker = d3.select('#marker');
          const p = linePath.node().getPointAtLength(t * l);
          marker.attr('transform', 'translate(' + p.x + ',' + p.y + ')'); //move marker
          return interpolate(t);
        };
      }; //end tweenDash

      // Use Leaflet to implement a D3 geometric transformation.
      // the latLngToLayerPoint is a Leaflet conversion method:
      // Returns the map layer point that corresponds to the given geographical
      // coordinates (useful for placing overlays on the map).
      function projectPoint(x, y) {
        const point = map.latLngToLayerPoint(new L.LatLng(y, x));
        this.stream.point(point.x, point.y);
      } //end projectPoint

      // when the user zooms in or out you need to reset
      // the view
      map.on('moveend ', reset);

      // this puts stuff on the map!
      const draw = () => {
        reset();
        transition();
      };
      draw();
    })
  );

const layerPointToLatLng = (point, map) => {
  var projectedPoint = L.point(point).add(map.getPixelOrigin());
  return map.unproject(projectedPoint, map.getZoom());
};
