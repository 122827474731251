import { animatePath } from './animate-path';
import * as L from '../leaflet/leaflet-gpx';
import * as d3 from 'd3';
// import * as Tangram from 'tangram';
import {
  allRoute,
  gobackClass,
  prevClass,
  nextClass,
  prevnextClass,
  trackOpts,
  tracks,
} from './options';
import chart from './chart';

const hideAllTracksInfo = () => {
  tracks.forEach((t) =>
    document.querySelector(t.className).classList.add('hide')
  );
};

const Map = () => {
  let map = null;
  let svg = null;
  let g = null;
  let restart = null;
  let stepDisplayed = null;
  let animBlip = null;
  let animationRunning = false;
  const animePath = (trk) => {
    if (animationRunning) return;
    animationRunning = true;
    new L.GPX(trk.gpx, trackOpts).on('loaded', (e) => {
      const eData = e.target.get_elevation_data();
      const eChart = chart(
        eData.map((d) => ({ x: d[0], y: d[1] })),
        trk.chartContainer,
        onChartMouseOver,
        !L.Browser.mobile
      );
      eChart();
      map.flyToBounds(e.target.getBounds(), {
        paddingBottomRight: [50, 160],
        paddingTopLeft: [50, 50],
      });
      document.querySelector(
        `${trk.className}--dist`
      ).innerHTML = `${Math.round(e.target._info.length / 1000)}Km`;
      document.querySelector(
        `${trk.className}--dpos`
      ).innerHTML = `${Math.round(e.target._info.elevation.gain)}m`;
      document.querySelector(
        `${trk.className}--dneg`
      ).innerHTML = `${Math.round(e.target._info.elevation.loss)}m`;
      map.once('moveend', () => {
        animatePath({
          route: trk,
          g,
          svg,
          map,
          L,
        })
          .then(onAnimationEnd)
          .catch((e) => {
            console.log(e);
          });
      });
    });
  };
  const onChartMouseOver = (index) => {
    if (animBlip) animBlip(index);
  };
  const onAnimationEnd = ({ draw, showBlip }) => {
    animationRunning = false;
    restart = draw;
    animBlip = showBlip;
  };
  const onStepClicked = (e, index = false) => {
    // map.setZoom(map.getZoom() - 1);
    if (animationRunning) return;
    let newIndex;
    if (typeof index === 'number') {
      newIndex = index;
    } else {
      newIndex = Number(e.target.dataset.track) - 1;
    }
    stepDisplayed = newIndex;
    const track = tracks[newIndex];
    // remove everything from map
    clearMap();
    hideAllTracksInfo();
    // Hide and show ui
    document.querySelector(gobackClass).classList.remove('hide');
    document.querySelector(prevnextClass).classList.remove('hide');
    document.querySelector(track.className).classList.remove('hide');
    document.querySelector(allRoute.className).classList.add('hide');
    // lanch map animation
    animePath(track);
  };
  const showAllRoute = (reset = false) => {
    if (animationRunning) return;
    if (reset) {
      hideAllTracksInfo();
      clearMap();
      document.querySelector(gobackClass).classList.add('hide');
      document.querySelector(prevnextClass).classList.add('hide');
      document.querySelector(allRoute.className).classList.remove('hide');
    }
    animePath(allRoute);
  };
  const clearMap = () => {
    svg.selectAll('*').remove();
    svg = d3.select(map.getPanes().overlayPane).append('svg');
    g = svg.append('g');
  };
  const startMap = () => {
    map = L.map('map', {
      // zoomControl: false,
      scrollWheelZoom: false,
      dragging: !L.Browser.mobile,
    }).setView([50.291883, 4.073102], 8);
    const darkMode = false;
    // Tangram.leafletLayer({
    //   scene: tileScene,
    //   attribution:
    //     '<a href="https://mapzen.com/tangram" target="_blank">Tangram</a> | &copy; OSM contributors',
    // }).addTo(map);
    L.tileLayer(`https://{s}.basemaps.cartocdn.com/${
      darkMode ? "dark_all" : "light_all"
    }/{z}/{x}/{y}${L.Browser.retina ? "@2x.png" : ".png"}`, {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
    svg = d3.select(map.getPanes().overlayPane).append('svg');
    g = svg.append('g');

    // Hide all tracks and just keep global route
    hideAllTracksInfo();
    // Setup listener on each li of global content
    const stepsLinks = document
      .querySelector('.map--content--all--steps')
      .getElementsByTagName('li');
    [...stepsLinks].forEach((step) =>
      step.addEventListener('click', (e) => onStepClicked(e))
    );
    // Setup event listener for goBackLink
    document
      .querySelector(gobackClass)
      .addEventListener('click', () => showAllRoute(true));
    document.querySelector(nextClass).addEventListener('click', () => {
      const i = stepDisplayed + 1 > tracks.length - 1 ? 0 : stepDisplayed + 1;
      onStepClicked(null, i);
    });
    document.querySelector(prevClass).addEventListener('click', () => {
      const i = stepDisplayed - 1 < 0 ? tracks.length - 1 : stepDisplayed - 1;
      onStepClicked(null, i);
    });
    // First call show all track
    showAllRoute();
  };
  const restartAnimation = () => {
    if (restart) restart();
  };
  return {
    startMap,
    restart: restartAnimation,
  };
};

export default Map;
