// Statics files imports to work with Parcel
import allGeosrc from '../../gpx/all.geojson';
import allsrc from '../../gpx/all.gpx';
import step1src from '../../gpx/step1.gpx';
import step2src from '../../gpx/step2.gpx';
import step3src from '../../gpx/step3.gpx';
import step4src from '../../gpx/step4.gpx';
import step5src from '../../gpx/step5.gpx';
import step6src from '../../gpx/step6.gpx';
import step7src from '../../gpx/step7.gpx';
import step1Geosrc from '../../gpx/step1.geojson';
import step2Geosrc from '../../gpx/step2.geojson';
import step3Geosrc from '../../gpx/step3.geojson';
import step4Geosrc from '../../gpx/step4.geojson';
import step5Geosrc from '../../gpx/step5.geojson';
import step6Geosrc from '../../gpx/step6.geojson';
import step7Geosrc from '../../gpx/step7.geojson';
import pinStart from '../../images/start.png';
import pinEnd from '../../images/stop.png';
import pinWpt from '../../images/wpt.png';
//

export const gobackClass = '.map--content--back--all';
export const prevClass = '.map--content--previous';
export const nextClass = '.map--content--next';
export const prevnextClass = '.map--content--prevnext';

export const tracks = [
  {
    gpx: step1src,
    geo: step1Geosrc,
    name: '13/06 Liège - Weisten',
    className: '.map--content--step1',
    chartContainer: '#elevationChart1',
  },
  {
    gpx: step2src,
    geo: step2Geosrc,
    name: '14/06 Weisten - Florenville',
    className: '.map--content--step2',
    chartContainer: '#elevationChart2',
  },
  {
    gpx: step3src,
    geo: step3Geosrc,
    name: '15/06 Florenville - Chimay',
    className: '.map--content--step3',
    chartContainer: '#elevationChart3',
  },
  {
    gpx: step4src,
    geo: step4Geosrc,
    name: '16/06 Chimay - Mouscron',
    className: '.map--content--step4',
    chartContainer: '#elevationChart4',
  },
  {
    gpx: step5src,
    geo: step5Geosrc,
    name: '17/06 Mouscron - Ostende',
    className: '.map--content--step5',
    chartContainer: '#elevationChart5',
  },
  {
    gpx: step6src,
    geo: step6Geosrc,
    name: '18/06 Ostende - Rijkevorsel',
    className: '.map--content--step6',
    chartContainer: '#elevationChart6',
  },
  {
    gpx: step7src,
    geo: step7Geosrc,
    name: '19/06 Rijkevorsel - Liège',
    className: '.map--content--step7',
    chartContainer: '#elevationChart7',
  },
];

export const allRoute = {
  start: 'Liège',
  end: 'Liège',
  className: '.map--content--all',
  gpx: allsrc,
  geo: allGeosrc,
  chartContainer: '#elevationChartAll',
};

export const trackOpts = {
  async: true,
  polyline_options: {
    color: '#FB4471',
    opacity: 0.75,
    weight: 2,
    lineCap: 'round',
  },
  marker_options: {
    startIconUrl: pinStart,
    endIconUrl: pinEnd,
    wptIconTypeUrls: false,
    wptIconUrls: {
      '': pinWpt,
    },
    wptIconsType: false,
    wptIcons: false,
    clickable: true,
  },
};
