import wrkts from '../../workouts.csv';
import * as d3 from 'd3';

const Workouts = {
  init: () => {
    const margin = { top: 20, right: 20, bottom: 90, left: 120 },
      width = 800 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    const x = d3.scaleBand().range([0, width]).padding(0.1);

    const y = d3.scaleLinear().range([height, 0]);

    const svg = d3
      .select('#wrktchart')
      .append('svg')
      .attr('id', 'svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    const div = d3
      .select('#wrktchart')
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);

    d3.csv(wrkts, d3.autoType).then((data) => {
      console.log(d3.sum(data, (d) => d.distance))
      console.log(d3.sum(data, (d) => d.calories))
      // Convert strings to int
      // data.forEach(d => {
      //   d.distance = +d.distance;
      //   d.elev_gain = +d.elev_gain;
      //   d.elev_loss = +d.elev_loss;
      //   d.calories = +d.calories;
      //   d.max_speed = +d.max_speed;
      //   d.avg_speed = +d.avg_speed;
      // });
      var nested_data = d3.rollup(
        data,
        (v) => d3.sum(v, (d) => d.distance),
        (d) => d.date.replace(/^(\d{4}-\d{2}).*$/, '$1')
      );
      const grouped = [];
      nested_data.forEach((val, key) => grouped.push({
        date: key,
        distance: Math.round(val)
      }));
      grouped.reverse();
      x.domain(grouped.map((d) => d.date));
      y.domain([0, d3.max(grouped, (d) => d.distance)]);
      // Ajout de l'axe X au SVG
      // Déplacement de l'axe horizontal et du futur texte (via la fonction translate) au bas du SVG
      // Selection des noeuds text, positionnement puis rotation
      svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('dx', '-.8em')
        .attr('dy', '.15em')
        .attr('transform', 'rotate(-65)');

      // Ajout de l'axe Y au SVG avec 6 éléments de légende en utilisant la fonction ticks (sinon D3JS en place autant qu'il peut).
      svg.append('g').call(d3.axisLeft(y).ticks(6));

      // Ajout des bars en utilisant les données de notre fichier data.tsv
      // La largeur de la barre est déterminée par la fonction x
      // La hauteur par la fonction y en tenant compte de la population
      // La gestion des events de la souris pour le popup
      svg
        .selectAll('.bar')
        .data(grouped)
        .enter()
        .append('rect')
        .attr('class', 'bar')
        .attr('x', (d) => x(d.date))
        .attr('width', x.bandwidth())
        .attr('y', (d) => y(d.distance))
        .attr('height', (d) => height - y(d.distance))
        .on('mouseover', function (d, i) {
          div.transition().duration(200).style('opacity', 0.9);
          div
            .html('distance: ' + i.distance + 'km')
            .style('left',  d.pageX + 'px')
            .style('top',  d.pageY + 'px');
        })
        .on('mouseout', function (d) {
          div.transition().duration(500).style('opacity', 0);
        });
    });
  },
};

export default Workouts;
