import * as fa from '@fortawesome/fontawesome-free/js/all';
import burger from './utils/burger';
import Map from './utils/map';
import Workouts from './utils/workouts';

document.addEventListener('DOMContentLoaded', () => {
  // Map
  let mapApp = null;
  const mapContainer = document.getElementById('map');
  if (mapContainer) {
    try {
      mapApp = Map();
      mapApp.startMap();
    } catch (error) {
      console.error(error);
    }
  }

  // Burger menu
  const hideMenu = burger('nav-menu', '.burger-icon');

  // Smooth scroll
  const links = document.querySelectorAll('.smooth-scroll');
  for (const link of links) {
    link.addEventListener('click', function (e) {
      e.preventDefault();
      hideMenu();
      const href = this.getAttribute('href');
      const offsetTop = document.querySelector(href).offsetTop - 67;
      if (href === '#path' && mapApp) {
        mapApp.restart();
      }
      scroll({
        top: offsetTop,
        behavior: 'smooth',
      });
    });
  }

  // Workouts
  const workoutsContainer = document.getElementById('workouts');
  if (workoutsContainer) {
    Workouts.init();
  }

  // Goal progress
});
